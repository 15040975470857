<template>
    <div>
    </div>
</template>
<script>
export default {
    methods: {
        getDollarsFormat() {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
            })
        },
        getMonth() {
            return [
                { id: '1', label: 'January' },
                { id: '2', label: 'February' },
                { id: '3', label: 'March' },
                { id: '4', label: 'April' },
                { id: '5', label: 'May' },
                { id: '6', label: 'June' },
                { id: '7', label: 'July' },
                { id: '8', label: 'August' },
                { id: '9', label: 'September' },
                { id: '10', label: 'Octuber' },
                { id: '11', label: 'November' },
                { id: '12', label: 'December' },
            ]
        },
        colorGenerator() {
            // Generar valores aleatorios para los componentes de color (rojo, verde, azul)
            const rojo = Math.floor(Math.random() * 256);
            const verde = Math.floor(Math.random() * 256);
            const azul = Math.floor(Math.random() * 256);

            // Convertir los valores de color a su representación hexadecimal
            const colorHex = `#${rojo.toString(16).padStart(2, '0')}${verde.toString(16).padStart(2, '0')}${azul.toString(16).padStart(2, '0')}`;

            return colorHex;
        },
        dayDiferences(fecha1, fecha2) {
            if (fecha1 == 0 || fecha2 == 0) return "0"
            // Parsea las fechas en formato "año-mes-día" a objetos Date
            const date1 = new Date(fecha1);
            const date2 = new Date(fecha2);

            // Calcula la diferencia en milisegundos
            const diferenciaMs = date2 - date1;

            // Convierte la diferencia de milisegundos a días
            const diferenciaDias = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24));

            return diferenciaDias;
        },
        listaColores(indx = false) {
            const colores = [
                "#80c4f5",
                "#aed6f9",
                "#fbd99a",
                "#f9a69a",
                "#84b6f3",
                "#9ed3f3",
                "#95f9b9",
                "#f5fab4",
                "#f9cae7",
                "#1094df",
                "#00f0b1",
                "#1ebdb9",
                "#138FCF",
                "#14CAD9",
                "#1CC2A2",
                "#14D979",
                "#13CF3F",
                "#CF0C0A",
                "#D90B6B",
                "#C200BB",
                "#A40BD9",
                "#670ACF",
                "#004b7f",
            ]
            return typeof indx === 'number' ? colores[indx] : colores
        }
    }
}
</script>