<template>
    <b-modal :visible="seeCompareFacilities" @change="(val) => $emit('update:seeCompareFacilities', val)" size="xl"
        title="compare facilities">
        <b-row>
            <b-col>
                <label>Start date</label>
                <flat-pickr v-model="start_date" :config="config" class="form-control" placeholder="MM-DD-YYYY"
                    type="date" />
            </b-col>
            <b-col>
                <label>End date</label>
                <flat-pickr v-model="end_date" :config="configEnd" class="form-control" placeholder="MM-DD-YYYY"
                    type="date" />
            </b-col>
            <b-col>
                <div class="d-flex justify-content-between">
                    <label for="">Facilities</label>
                    <b-form-checkbox name="checkbox-input" v-model="check" @change="checkFacilities()"
                        :disabled="start_date == null || end_date == null" />
                </div>
                <treeselect v-model="facilities" :async="true" :load-options="searchFacility" :disabled="checkDisabled()"
                    :defaultOptions="true" :normalizer="normalizer" multiple />
            </b-col>
        </b-row>
        <b-button class="mt-3" block variant="primary" @click="compareFacilities" :disabled="loading">Search</b-button>
        <hr />
        <div class="align-center" v-if="loading">
            <b-spinner variant="primary" type="grow" />
        </div>
        <div class="container">
            <b-card border-variant="primary" :title="facility" class="item"
                v-for="({ TOTALS: { income, expenses, net_income, cash, cc }, AVG_TOTAL, AVG_NC, AVG_NP, AVG_NC_NP, VPD, NON_PROFIT_VPD, TOTAL_VPD, facility }, key) in showFacilities"
                :key="key">
                <p>
                    <b>total income:</b> {{ income }}
                    <br>
                    <b>Total Expences:</b> {{ expenses }}
                    <br>
                    <b>Total Net Income:</b> {{ net_income }}
                    <br>
                    <b>Total Income Cash:</b> {{ cash }}
                    <br>
                    <b>Total Income CC:</b> {{ cc }}
                    <br>
                    <b>Patient Avg Charge:</b> {{ AVG_TOTAL }}
                    <br>
                    <b>NC Avg Charge:</b> {{ AVG_NC }}
                    <br>
                    <b>NP Avg Charge:</b> {{ AVG_NP }}
                    <br>
                    <b>NC and NP AVg Charge:</b> {{ AVG_NC_NP }}
                    <br>
                    <b>Chargeable VPD:</b> {{ VPD }}
                    <br>
                    <b>Less Chargeable VPD:</b> {{ NON_PROFIT_VPD }}
                    <br>
                    <b>Total VPD:</b> {{ TOTAL_VPD }}
                </p>
            </b-card>
        </div>
        <summations class="mt-3" :sumData="sumData" />
        <ToastNotification ref="toast" />
    </b-modal>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner
} from "bootstrap-vue";

import ToastNotification from "@/components/ToastNotification";
import summations from "../dashboard/components/summations.vue"
import axios from "@/core/services/api/admin/adminData";
import axiosF from "@/core/services/api/admin/facilities";


export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        ToastNotification,
        summations
    },
    model: {
        prop: "seeCompareFacilities",
        event: "update:seeCompareFacilities",
    },
    props: {
        seeCompareFacilities: {
            type: Boolean,
            default: false,
            required: true
        },
    },
    data() {
        return {
            facilities: [],
            data: [],
            end_date: null,
            start_date: null,
            sumData: [],
            config: {
                dateFormat: "Y-m-d H:i:S",
                enableSeconds: true,
                allowInput: true,
                enableTime: true
            },
            configEnd: {
                dateFormat: "Y-m-d H:i:S",
                enableSeconds: true,
                allowInput: true,
                enableTime: true
            },
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
            allFacilities: [],
            showFacilities: [],
            check: false,
            loading: false
        };
    },
    created() {
        axiosF.facilityFilter(10, { name: "" }).then(({ data }) => {
            this.allFacilities = data
        });
    },
    methods: {
        compareFacilities() {
            if (this.facilities != null) {
                this.loading = true
                const newPayload = {
                    start_date: this.start_date,
                    end_date: this.end_date,
                    facilities_id: this.facilities
                }
                axios.compareFacilities(newPayload).then(({ data: { facilities, year_data } }) => {
                    this.loading = false
                    this.showFacilities = facilities
                    this.sumData = year_data
                }).catch((e) => {
                    this.loading = false
                })
            }
        },
        checkFacilities() {
            const newFacilities = []
            this.facilities = this.allFacilities.forEach((facility) => {
                newFacilities.push(facility.id)
            })
            this.facilities = newFacilities
        },
        checkDisabled() {
            if (this.start_date == null || this.end_date == null) return true
            if (this.check) return true
        },
        searchFacility({ action, searchQuery, callback }) {
            if (action === "ASYNC_SEARCH") {
                const datos = {
                    name: searchQuery,
                };
                axiosF.facilityFilter(10, datos).then(({ data }) => {
                    callback(null, data);
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
