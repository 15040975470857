<template>
    <b-modal :visible="isCuponsModalActive" @change="(val) => $emit('update:isCuponsModalActive', val)" title="See Cupons"
        size="xl">
        <b-card
            v-for="({ CUPONS, CUPONS_PAGINATE: { data, current_page, from, to, total, per_page, }, title }, index) in cupons"
            :key="index" :title="title" v-if="CUPONS && CUPONS.length">
            <b-table :items="data" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
                show-empty empty-text="No cupons found!">
                <!-- <template #cell(created_at)="data">
                        {{ new Date(data.item.created_at).toLocaleDateString() }}
                    </template> -->
            </b-table>
            <PaginationTable :dataMeta="dataMeta({ from, to, total })" :totalUsers="getTotal(total)"
                :perPage="getPerPage(per_page)" :currentPage="getCurrentPage(current_page, index)"
                @page-changed="pageChanged" />
        </b-card>
    </b-modal>
</template>

<script>
import {
    BModal,
    BCard,
    BCardGroup,
    BRow,
    BCol,
    BButton,
    BTable
} from "bootstrap-vue";
import PaginationTable from "@/components/PaginationTable";


export default {
    data() {
        return {
            fields: [
                "id",
                "visit_date",
                "patient_name",
                "facility",
                "cc",
                "cash",
                "ajustment",
                "total"
            ],
            perPageOptions: [10, 25, 50, 100].map((i) => ({
                id: i,
                label: `Show ${i} Entries`,
            })),
            currPage: []
        }
    },
    components: {
        BRow,
        BCol,
        BButton,
        BCard,
        BCardGroup,
        BTable,
        PaginationTable
    },
    model: {
        prop: "isCuponsModalActive",
        event: "update:isCuponsModalActive",
    },
    props: {
        isCuponsModalActive: {
            type: Boolean,
            default: false,
            required: true
        },
        cupons: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    methods: {
        dataMeta({ from, to, total }) {
            return {
                from: from ?? 1,
                to: to ?? 1,
                of: total ?? 0
            }
        },
        getTotal(total) {
            return total ?? 1
        },
        getPerPage(per_page) {
            return per_page ?? 1
        },
        getCurrentPage(current_page, index) {
            if (!this.currPage[index]) {
                this.currPage[index] = { page: 1 }; // Inicializar como objeto con propiedad 'page'
            }
            this.currPage[index].page = current_page ?? 1; // Asignar 'current_page' a 'page' dentro del objeto
            return this.currPage[index].page;
        },
        pageChanged(page) {
            this.$emit("changePage", page)
        },
    },
};
</script>