<template>
  <b-container>
    <b-row>
      <b-col class="pt-2">
        <label>User</label>
        <treeselect
          v-model="filters.user_id"
          :async="true"
          :load-options="searchUser"
          :defaultOptions="true"
          :normalizer="normalizerUser" />
      </b-col>
      <div class="separacion w-100"></div>
      <b-col class="pt-2">
        <label>Facility</label>
        <treeselect
          v-model="filters.facilities_id"
          :async="true"
          :load-options="searchFacility"
          :defaultOptions="true"
          :normalizer="normalizer"
          multiple />
      </b-col>
      <div class="separacion w-100"></div>
    </b-row>
    <b-row>
      <b-col class="pt-2">
        <label>Rol</label>
        <treeselect
          v-model="filters.rol_id"
          :options="rolOptions"
          :normalizer="normalizer">
        </treeselect>
      </b-col>
      <div class="separacion w-100"></div>
      <b-col class="pt-2">
        <label>Start date</label>
        <flat-pickr
          v-model="filters.start_date"
          :config="config"
          class="form-control"
          placeholder="MM-DD-YYYY"
          type="date" />
      </b-col>
      <div class="separacion w-100"></div>
      <b-col class="pt-2">
        <label>End date</label>
        <flat-pickr
          v-model="filters.end_date"
          :config="configEnd"
          class="form-control"
          placeholder="MM-DD-YYYY"
          type="date" />
      </b-col>
      <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
        <b-button variant="secondary" @click="seeCuponsBool = true" class="mr-1">
          See cupons
        </b-button>
        <b-button variant="info" @click="seeCompareFacilities = true" class="mr-1">
          Compare facilities
        </b-button>
        <b-button variant="success" @click="emitExcel" class="mr-1">
          <span v-if="!loadingExcel">Excel</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
        <b-button variant="warning" @click="resetFilter" class="mr-1"> Clear </b-button>
        <b-button variant="primary" @click="handleFormSubmit()" :disabled="loadingSearh">
          <span v-if="!loadingSearh">Search</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </b-col>
    </b-row>
    <compareFacilities :seeCompareFacilities.sync="seeCompareFacilities" />
    <ToastNotification ref="toast" />
    <Cupons
      ref="tablarda"
      :tableData="tableData"
      @paginated="cuponsPage = $event"
      :seeCupons.sync="seeCupons"
      @sendDataTable="dataTable = $event" />
    <SeeCupons
      :cupons="cupons"
      :isCuponsModalActive.sync="seeCuponsBool"
      @closeModalCupons="closeModalCupons"
      @changePage="cuponsPage = $event" />
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

import SpinnerLoading from '@/components/SpinnerLoading'
import ToastNotification from '@/components/ToastNotification'
import axios from '@/core/services/api/admin/adminData'
import axiosU from '@/core/services/api/admin/user'
import axiosR from '@/core/services/api/admin/roles'
import axiosF from '@/core/services/api/admin/facilities'
import compareFacilities from './compareFacilities.vue'
import Cupons from './cupons.vue'
import SeeCupons from './seeCupons.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    SpinnerLoading,
    ToastNotification,
    compareFacilities,
    Cupons,
    SeeCupons,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  data() {
    return {
      rolOptions: [],
      seeCuponsBool: false,
      loadingExcel: false,
      loadingSearh: false,
      cupons: [],
      filters: {
        user_id: null,
        facilities_id: null,
        rol_id: null,
        end_date: null,
        start_date: null,
        data_excel: false,
      },
      config: {
        dateFormat: 'm-d-Y H:i:S',
        enableSeconds: true,
        allowInput: true,
        enableTime: true,
      },
      configEnd: {
        dateFormat: 'm-d-Y H:i:S',
        enableSeconds: true,
        allowInput: true,
        enableTime: true,
      },
      tableData: {},
      dataExcel: {},
      seeCompareFacilities: false,
      seeCupons: false,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      normalizerUser(node) {
        return {
          id: node.id,
          label: `${node.name} ${node.lastname}`,
        }
      },
      cuponsPage: 1,
    }
  },
  async created() {
    const firstDate = `${new Date().getMonth() + 1}-${new Date().getDate()}-${new Date().getFullYear()}`

    this.filters.start_date = `${firstDate} 09:00:00`
    this.filters.end_date = `${firstDate} 22:00:00`

    axiosR.rolesList().then(({ data }) => (this.rolOptions = data))

    this.searchFilter()

    // axios.getAdminData(this.filters).then(({ data }) => {
    //     console.log(data);
    //     this.$emit("getItems", data);
    // });
  },
  watch: {
    page() {
      axios.getAdminDataPaginated(this.page, this.filters).then(({ data }) => {
        data.user = this.filters.user_id
        data.dates = {
          end_date: this.filters.end_date,
          start_date: this.filters.start_date,
        }
        this.setCupons(data['CAMPAIGN_CUPONS'])
        this.sendData(data)
      })
    },
    cuponsPage() {
      axios.getAdminDataPaginated(this.cuponsPage, this.filters).then(({ data }) => {
        data.user = this.filters.user_id
        data.dates = {
          end_date: this.filters.end_date,
          start_date: this.filters.start_date,
        }
        this.setCupons(data['CAMPAIGN_CUPONS'])
        this.tableData = data
      })
    },
    // filters: {
    //   handler() {
    //     this.searchFilter()
    //   },
    //   deep: true,
    // },
  },
  methods: {
    setCupons(campaign_cupons) {
      const newCupons = []
      Object.entries(campaign_cupons).forEach(([key, value]) => {
        value.title = key
        newCupons.push(value)
      })
      this.cupons = newCupons
      //   console.log('cupones:', this.cupons)
    },
    closeModalCupons() {
      this.seeCuponsBool = false
    },
    sendData(data) {
      this.tableData = data
      this.$emit('getItems', data)
    },
    handleFormSubmit() {
      if (this.validateForm()) {
        this.searchFilter()
      }
    },
    validateForm() {
      const { start_date, end_date } = this.filters
      const isValid = start_date !== null && end_date !== null
      //   console.log('isValid', isValid)
      return isValid
    },
    searchFilter(isExcel = true) {
      if (isExcel) this.filters['data_excel'] = false
      if (!this.validateForm()) return null

      if (!isExcel) this.loadingExcel = true
      if (isExcel) this.loadingSearh = true

      if (!this.filters.start_date || !this.filters.end_date) {
        if (!isExcel) this.loadingExcel = false
        if (isExcel) this.loadingSearh = false
        return this.$refs.toast.warning('Check the dates!')
      }

      axios
        .getAdminData(this.filters)
        .then(({ data }) => {
          if (data) {
            data.facility = this.filters.facilities_id
            data.user = this.filters.user_id
            data.dates = {
              end_date: this.filters.end_date,
              start_date: this.filters.start_date,
            }
            
            if (!isExcel) this.$emit('dataExcel', data)
            this.setCupons(data['CAMPAIGN_CUPONS'])
            this.sendData(data)
          }

          if (isExcel) this.loadingSearh = false
          if (!isExcel) this.loadingExcel = false
          if (!isExcel) this.tableData = data
          if (!isExcel) this.dataExcel = data
          if (!isExcel) this.$emit('getExcel')
          // console.log(data)
        })
        .catch(error => {
          console.error('Error en la petición axios:', error)
        })
    },
    emitExcel() {
      this.filters['data_excel'] = true
      this.searchFilter(false)
    },
    resetFilter() {
      const filter = this.filters
      for (const iterator in filter) {
        this.filters[iterator] = null
      }
    },
    searchUser({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        const datos = {
          name: searchQuery,
        }
        axiosU.userFilter(10, datos).then(({ registro: { data } }) => {
          callback(null, data)
        })
      }
    },
    searchFacility({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        const datos = {
          name: searchQuery,
        }
        axiosF.facilityFilter(10, datos).then(({ data }) => {
          callback(null, data)
        })
      }
    },
  },
}
</script>

<style>
@media (min-width: 970px) {
  .separacion {
    display: none;
  }
}
</style>
