var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"visible":_vm.isCuponsModalActive,"title":"See Cupons","size":"xl"},on:{"change":function (val) { return _vm.$emit('update:isCuponsModalActive', val); }}},_vm._l((_vm.cupons),function(ref,index){
var CUPONS = ref.CUPONS;
var ref_CUPONS_PAGINATE = ref.CUPONS_PAGINATE;
var data = ref_CUPONS_PAGINATE.data;
var current_page = ref_CUPONS_PAGINATE.current_page;
var from = ref_CUPONS_PAGINATE.from;
var to = ref_CUPONS_PAGINATE.to;
var total = ref_CUPONS_PAGINATE.total;
var per_page = ref_CUPONS_PAGINATE.per_page;
var title = ref.title;
return (CUPONS && CUPONS.length)?_c('b-card',{key:index,attrs:{"title":title}},[_c('b-table',{staticClass:"position-relative",attrs:{"items":data,"fields":_vm.fields,"hover":"","bordered":"","responsive":"","primary-key":"id","show-empty":"","empty-text":"No cupons found!"}}),_c('PaginationTable',{attrs:{"dataMeta":_vm.dataMeta({ from: from, to: to, total: total }),"totalUsers":_vm.getTotal(total),"perPage":_vm.getPerPage(per_page),"currentPage":_vm.getCurrentPage(current_page, index)},on:{"page-changed":_vm.pageChanged}})],1):_vm._e()}),1)}
var staticRenderFns = []

export { render, staticRenderFns }